import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import './App.css';

import UploadPage from './components/UploadPage';
import About from './components/About';

const App: React.FC = () => {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route from='/:id([a-zA-Z0-9]+)' component={(props: any) => (<About id={props.match.params.id}/>)}/>
          <Route path="/" exact component={UploadPage}/>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
