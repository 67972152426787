import React from 'react';
import {Helmet} from 'react-helmet';

interface Props {
  imageUrl: string;
  name: string;
}

export default function ImagePreview({imageUrl, name}: Props) {
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={name} />
        <meta name="twitter:image" content={imageUrl} />
        <title>{name}</title>
      </Helmet>
      <img alt="" src={imageUrl} />
    </>
  );
}
