import AceEditor from 'react-ace';
import React, {useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';

interface Props {
  initText: string;
  uploader: (text: string) => void;
}

export default function TextEditor({initText, uploader}: Props) {
  const [text, setText] = useState(initText);
  const upload = () => uploader(text);
  useHotkeys('ctrl+s', (event) => {
    event.preventDefault();
    upload();
  });
  return (
    <>
      <AceEditor width="100%" className={'text-preview-editor'} showPrintMargin={false}
        theme="github" value={text} onChange={setText} highlightActiveLine={false}/>
      <button onClick={upload}>Click or CTRL-S</button>
    </>
  );
}
