import React, {useEffect, useState} from 'react';
import AceEditor from 'react-ace';
import './TextPreview.css';
import 'brace/theme/github';

import {getMode} from './AceModelist';
import {Helmet} from 'react-helmet';
import {useHotkeys} from 'react-hotkeys-hook';

interface Props {
  extension: string;
  url: string;
  name: string;
}

export function canPreview(extension: string): boolean {
  return getMode(extension) !== null;
}

export default function TextPreview({extension, url, name}: Props) {
  const [mode] = useState(getMode(extension) as string);
  // I don't know if this is Kosher
  require(`brace/mode/${mode}`);

  const [content, setContent] = useState('Loading...');

  useEffect(() => {
    fetch(url).then((data) => data.text()).then(setContent);
  }, [url, setContent]);

  const copy = () => {
    navigator.clipboard.writeText(content);
  };
  useHotkeys('ctrl+c', copy, [content]);

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={name}/>
        <title>{name}</title>
      </Helmet>
      <AceEditor width="100%" className={'text-preview-editor'} showPrintMargin={false}
        theme="github" value={content} mode={mode} cursorStart={0} navigateToFileEnd={true}
        readOnly={true} highlightActiveLine={false}/>
      <button onClick={copy}>Click or CTRL-C to Copy Text</button>
    </>
  );
}
