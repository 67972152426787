import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import FilePreview from './preview/FilePreview';

interface Params {
  id: string
}

interface FileData {
  url: string;
  created: string;
  size: number;
  name: string;
  error?: string;
}

export default function About({id}: Params) {
  const [data, setData] = useState(undefined as unknown as FileData);
  useEffect(() => {
    fetch(`/.netlify/functions/about/${id}`).then((data) => data.json())
        .then((data) => data as unknown as FileData).then(setData);
  }, [id]);

  if (!data) {
    return (<p>Loading...</p>);
  } else if (data.error) {
    return (<p>Error: {data.error}</p>);
  }

  return (
    <div>
      <FilePreview fileName={data.name} fileUrl={data.url}/>
      <p>Name: {data.name}</p>
      <p>Created: {new Date(Date.parse(data.created)).toDateString()}</p>
      <p>Size: {bytesToHuman(data.size)}</p>
      <a href={data.url}>Download</a>
      <Link to="/"><p>Upload Something Else</p></Link>
    </div>
  );
}

function bytesToHuman(bytes: number): string {
  if (bytes === 0) {
    return '0 Bytes';
  } else {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i) * 10) / 10 + ' ' + sizes[i];
  }
}
