import React from 'react';
import {Helmet} from 'react-helmet';

interface Props {
  url: string;
  name: string;
}

export default function AudioPreview({url, name}: Props) {
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={name} />
        <meta name="twitter:player" content={url} />
        <title>{name}</title>
      </Helmet>
      <audio controls src={url}>Your browser does not support audio playback</audio>
    </>
  );
}
