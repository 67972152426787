interface PostData {
  url: string;
  fields: any;
  key: string;
}

export async function upload(file: File, progress: (percent: number) => void): Promise<string> {
  return uploadRaw(file.name, file, progress);
}

export async function uploadRaw(fileName: string, fileData: any, progress: (percent: number) => void): Promise<string> {
  const fileMeta = await createPutUrl(fileName);
  return new Promise((resolve: any, reject: any) => {
    const req = new XMLHttpRequest();

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percent = (event.loaded / event.total) * 100;
        progress(percent);
      }
    });

    req.upload.addEventListener('load', () => {
      if (req.response) {
        // todo check 200
      }
      progress(100);
      resolve(fileMeta.key);
    });

    req.upload.addEventListener('error', () => {
      reject(req.response);
    });

    const formData = new FormData();
    for (const [key, value] of Object.entries(fileMeta.fields)) {
      formData.append(key, value as string);
    }
    formData.append('file', fileData, fileName);
    req.open('POST', fileMeta.url);
    req.send(formData);
  });
}

export async function createPutUrl(file: string): Promise<PostData> {
  return await fetch('/.netlify/functions/upload', {
    method: 'POST',
    body: JSON.stringify({fileName: file}),
  }).then((data) => data.json() as unknown as PostData);
}
