import React, {useState} from 'react';
import {uploadRaw} from 'api/uploader';
import Uploader from 'components/Uploader';
import Uploading from 'components/Uploading';
import {Redirect} from 'react-router-dom';
import {useHotkeys} from 'react-hotkeys-hook';
import TextEditor from 'components/TextEditor';

type state = 'file' | 'editing' | 'uploading' | 'done' | 'error'

export default function UploadPage() {
  const [state, setState] = useState('file' as state);
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState('');
  const [editContent, setEditContent] = useState();

  const uploadContent = (fileName: string, fileData: string | Blob) => {
    setState('uploading');
    setProgress(0);
    uploadRaw(fileName, fileData, setProgress).then((key: string) => {
      setState('done');
      setKey(key);
    }).catch(() => setState('error'));
  };

  const uploadFiles = (files: File[]) => {
    const file = files[0];
    uploadContent(file.name, file);
  };

  const uploadText = (text: string) => {
    const fileData = new Blob([text], {type: 'text/plain'});
    uploadContent('snippet.txt', fileData);
  };

  useHotkeys('ctrl+v', () => {
    navigator.clipboard.readText().then((text) => {
      setEditContent(text);
      setState('editing');
    });
  });

  if (state === 'file') {
    return (<Uploader onDrop={uploadFiles}/>);
  } else if (state === 'uploading') {
    return (<Uploading progress={progress}/>);
  } else if (state === 'editing') {
    return <TextEditor initText={editContent} uploader={uploadText}/>;
  } else {
    return (<Redirect to={`/${key}`}/>);
  }
}
