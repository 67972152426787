import React from 'react';
import {Helmet} from 'react-helmet';

interface Props {
  name: string;
  fileUrl: string;
}

export default function VideoPreview({fileUrl, name}: Props) {
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={name} />
        <meta name="twitter:player" content={fileUrl} />
        <title>{name}</title>
      </Helmet>
      <video controls className={'video-preview'}>
        <source src={fileUrl}/>
        Your browser does not support these type of videos. Please download and play it locally.
      </video>
    </>
  );
}
