import React from 'react';

import VideoPreview from 'components/preview/video/VideoPreview';
import TextPreview, {canPreview} from 'components/preview/text/TextPreview';
import ImagePreview from 'components/preview/image/ImagePreview';
import AudioPreview from 'components/preview/audio/AudioPreview';

interface Props {
  fileName: string;
  fileUrl: string;
}

export default function FilePreview({fileName, fileUrl}: Props) {
  const format = fileName.substring(fileName.lastIndexOf('.') + 1).toLocaleLowerCase();

  if (['mp4', 'ogg', 'webm'].includes(format)) {
    return <VideoPreview fileUrl={fileUrl} name={fileName}/>;
  } else if (['png', 'gif', 'jpg', 'jpeg'].includes(format)) {
    return <ImagePreview imageUrl={fileUrl} name={fileName} />;
  } else if (['wav', 'mp3', 'ogg'].includes(format)) {
    return <AudioPreview url={fileUrl} name={fileName}/>;
  } else if (canPreview(format)) {
    return <TextPreview extension={format} url={fileUrl} name={fileName} />;
  } else {
    return <>No preview available, sorry :(</>;
  }
}
